.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--header-tabs);

    &:last-child {
      color: #000000;
    }
  }
}

@include respond-up(L) {
  .breadcrumbs {
    padding-bottom: 30px;

    &__delimiter {
      padding: 0 10px;
    }

    &__item {
      font-size: 11px;
      line-height: 17px;
    }
  }
}

@include respond-down(M) {
  .breadcrumbs {
    padding: 20px 0 20px;

    &__list {
      flex-wrap: wrap;
    }

    &__item-svg {
      height: 4px;
      display: flex;
      align-items: center;
    }

    &__item {
      font-size: 14px;
      line-height: 17px;
      color: var(--header-tabs);
    }

    &__delimiter {
      padding: 0 5px;
      display: block;
      height: 4px;
    }
  }
}