.header {
  z-index: 200;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    color: white;
    display: inline-block;
    transition: var(--default-transition);
  }

  &__links-list {
    display: flex;
    align-items: center;
    background: var(--header-tabs);
    border-radius: 5px;
  }
}

@include respond-up(L) {
  .header {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 16px 0;

    &__top-mobile {
      display: none;
    }

    &__logo {
      width: 154px;
      height: 66px;
    }

    &__link-arrow {
      margin-left: 4px;
      display: flex;
      align-items: center;
      height: 5px;
      width: 5px;
      transition: var(--default-transition);
      &_mobile {
        display: none;
      }
    }

    &__link {
      position: relative;
      padding: 10px 24px;
      transition: var(--default-transition);
      white-space: nowrap;

      &_back {
        display: none;
      }

      &:hover {
        background-color: var(--grey-hover);
      }

      &._active {
        background-color: var(--grey-hover);
        .header__link-arrow {
          transform: rotate(-180deg);
        }
      }
    }

    &__expand-menu {
      z-index: 200;
      background-color: var(--grey-hover);
      position: absolute;
      top: 38px;
      left: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

      &._active {
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }
    }

    &__expand-item {
      white-space: nowrap;
      display: inline-block;
      padding: 10px 20px;
      border-bottom: 1px solid var(--header-boder);
    }

    &__expand-list {
      display: flex;
      flex-direction: column;
    }

    &__link-wrapper {
      display: flex;
      align-items: center;
    }

    &__orange-arrow {
      display: none;
    }

    &__hamburger {
      display: none;
    }
  }
}

@include respond-down(M) {
  .header {
    background-color: var(--header-bg);

    &__wrapper {
      min-height: 71px;
      position: relative;

    }

    &__logo {
      padding: 3px 0;
      svg {
        transform: scale(0.9);
      }
    }

    &__hamburger {
      padding: 10px 0;
      display: flex;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;

      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &__hamburger-box {
      width: 30px;
      height: 26px;
      display: inline-block;
      position: relative;
    }

    &__hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -1px;
    }

    &__hamburger-inner,
    &__hamburger-inner::before,
    &__hamburger-inner::after {
      width: 30px;
      height: 3px;
      background-color: var(--orange-color);
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &__hamburger-inner::before,
    &__hamburger-inner::after {
      content: "";
      display: block;
    }

    &__hamburger-inner::before {
      top: -7px;
    }

    &__hamburger-inner::after {
      bottom: -7px;
    }

    &__hamburger &__hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &__hamburger &__hamburger-inner::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &__hamburger &__hamburger-inner::after {
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &__hamburger_active &__hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &__hamburger_active &__hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }

    &__hamburger_active &__hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &__top-mobile-menu {
      position: fixed;
      top: 71px;
      background-color: var(--header-bg);
      left: 0;
      right: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      border-top: transparent;
      &::-webkit-scrollbar {
        display: none;
      }

      &._active {
        border-top: 1px solid #CCCCCC;
        height: calc(100vh - 71px) !important;
        max-height: 100vh !important;
        overflow-y: scroll !important;
        transition: max-height 1s ease-in-out;
      }
    }

    &__links-list {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      margin: 0 15px;
    }

    &__link {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
      color: #000000;
      text-align: left;
      padding: 20px 0;
    }

    &__item {
      width: 100%;
      border-bottom: 1px solid #CCCCCC;
    }

    &__drop {
      border-bottom: 1px solid #CCCCCC;
    }

    &__expand-menu {
      position: fixed;
      top: 72px;
      background-color: var(--header-bg);
      right: 0;
      overflow: hidden;
      width: 0;
      transition: width 0.5s cubic-bezier(0, 1, 0, 1);
      z-index: 1;

      &._active {
        z-index: 400;
        max-width: 100%;
        width: 100%;
        height: 100vh;
        padding: 0 15px;
      }
    }

    &__link-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__expand-list {
      &::-webkit-scrollbar {
        display: none;
      }
      display: flex;
      flex-direction: column;
      height: calc(100vh - 71px) !important;
      max-height: 100vh !important;
      overflow-y: scroll !important;
      transition: max-height 1s ease-in-out;
    }

    &__link-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__link {
      width: 100%;
    }

    &__orange-arrow {
      margin-right: 15px;
    }
  }
}

