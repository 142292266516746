article {
  &, p, li, span {
    font-family: var(--h-font);
  }

  h2, h3 {
    margin-bottom: 15px;
  }

  p {
    a {
      color: var(--orange-color);
      border-bottom: 1px solid var(--orange-color);
      &:hover {
        border-bottom: 2px solid var(--orange-color);
      }
    }
  }

  li {
    a {
      color: var(--orange-color);
      border-bottom: 1px solid var(--orange-color);
      &:hover {
        border-bottom: 2px solid var(--orange-color);
      }
    }
  }

  q {
    position: relative;
    padding: 30px 20px 0;
    background: var(--secondery-light-border);
    border: 1px solid var(--secondery-light-border);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: 5px solid var(--orange-color);
    margin-bottom: 22px;
    p {
      padding-top: 35px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
    &::before {
      background-repeat: no-repeat;
      background-size: contain;
      width: 35px;
      height: 35px;
      top: 25px;
      left: 20px;
      content: '';
      position: absolute;
      background-image: url('../../images/svg/quote.svg')
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul, ol {
    margin-bottom: 15px;
    li {
      position: relative;
      margin-bottom: 4px;
      padding-left: 16px;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        border-radius: 100px;
        display: block;
        height: 6px;
        width: 6px;
        min-width: 6px;
        background-color: #000000;
      }
    }
  }

  p {
    margin-bottom: 20px;
  }

  b {
    font-weight: 700;
  }
}

@include respond-up(L) {
  article {
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }

    ul, ol {

      li {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}

@include respond-down(M) {
  article {
    h2 {
      font-weight: 700;
      font-size: 29px;
      line-height: 33px;
    }
    h3 {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
    }

    ul, ol {
      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
    }

    p, li, span {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  }
}