.cookies {
  position: relative;

  &__text-wrapper {
    position: absolute;
    top: 50px;
    left: 140px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: var(--grey-border);
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.07);
  }

  &__text {
    min-width: 400px;
    max-width: 410px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-right: 30px;
  }

  &__link {
    display: inline-block;
    border-bottom: 1px solid #000000;
    line-height: 1;
  }
}