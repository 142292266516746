.articles {
  &__list {
    display: grid;
  }

  &__link {
    align-self: flex-start;
    grid-column-gap: 10px;
    display: grid;
    grid-template-columns: 10px 1fr;
  }

  &__svg {
    grid-column: 1/2;
  }

  &__created-at {
    font-family: var(--h-font);
    grid-column: 2/3;
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  &__item {

  }

  &__name {
    font-family: var(--h-font);
    grid-column: 2/3;
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-decoration-line: underline;
  }

}

@include respond-up(M) {
  .articles {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
    }

    &__created-at {
      font-size: 11px;
      line-height: 13px;
    }
  }
}

@include respond-down(S) {
  .articles {
    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
    &__created-at {
      font-size: 15px;
      line-height: 17px;
    }

    &__name {
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-decoration-line: underline;
    }
  }
}