.article-page {
  &__container {
    display: grid;
  }
  &__bottom-container {
    margin-top: -5px;
    background-color: var(--secondery-light-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    border-radius: 0 0 5px 5px;
  }

  &__time {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }

  &__created-at, &__author {
    strong {
      color: var(--header-tabs);
    }
  }

  &__author {
    &_strong {
      &-mob {
        display: none;
      }
    }
  }

  &__quote-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  &__quote {
    padding: 30px 20px;
    background: var(--secondery-light-border);
    border: 1px solid var(--secondery-light-border);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: 5px solid var(--orange-color);
  }

  &__svg {
    margin-bottom: 10px;
  }
  &__image-wrapper {
    position: relative;
    overflow: hidden;
    //&::before {
    //  content: '';
    //  position: absolute;
    //  top: -3px;
    //  left: 0;
    //  width: 177px;
    //  height: 100px;
    //  background: {
    //    position: center;
    //    repeat: no-repeat;
    //  };
    //  background-image: url('../../images/svg/wm.svg')
    //}
  }
}

@include respond-up(L) {
  .article-page {
    &__container {
      grid-template-columns: 1fr 370px;
      grid-column-gap: 26px;
    }

    &__h1 {
      margin-bottom: 30px;
    }

    &__nav-bar-wrapper {
      position: relative;
    }

    &__link-wrapper {
      display: flex;
      flex-direction: column;
        padding-left: 15px;
        position: relative;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        transition: var(--default-transition);
        &:hover {
          color: var(--orange-color);
        }
        &::before {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: var(--orange-color);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
    }

    &__nav-bar {
      background: #FFFFFF;
      border: var(--grey-border);
      border-radius: 5px;
      padding: 11px 20px;
      position: sticky;
      top: 120px;
    }

    &__nav-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      width: 100%;
      margin-bottom: 22px;
    }

    &__image-wrapper {
      margin-bottom: 30px;
    }

    &__h2 {
      margin-bottom: 30px;
      color: var(--orange-color);
    }

    &__main-image {
      height: 100%;
      width: 100%;
    }
  }
}

@include respond-down(M) {
  .article-page {
    &__main-image {
      width: 100%;
    }

    &__h1 {
      margin-bottom: 20px;
    }

    &__nav-bar {
      display: none;
    }

    &__h2 {
      color: var(--orange-color);
      margin-bottom: 20px;
    }

    &__image-wrapper {
      margin-bottom: 20px;
      //&::before {
      //  svg {
      //    width: 100px;
      //    height: 50px;
      //  }
      //  background-size: contain;
      //  width: 110px;
      //  height: 80px;
      //  top: -12px;
      //  left: -2px;
      //}
    }

    &__quote {
      margin-top: 15px;
    }

    &__created-at {
      strong {
        display: none;
      }
    }

    &__author {
      display: flex;
      align-items: center;
      &_strong {
        &-mob {
          margin-right: 2px;
          display: block;
        }
        &-desc {
          display: none;
        }
      }
    }
  }
}