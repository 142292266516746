.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  &__main {
    position: relative;
    flex: 1 1 auto;
  }


}

@include respond-up(L) {
  .layout {
    padding-top: 105px;
    &__main {
      padding-bottom: 30px;
    }
    &__cookies {
      position: absolute;
      bottom: 100px;
      right: 550px;
    }
  }
}

@include respond-down(M) {
  .layout {
    &__main {
      padding-bottom: 30px;
    }
    &__cookies {
      display: none;
    }
  }
}