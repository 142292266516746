.faq-page {
  display: grid;

  &__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  &__item-tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 8px;
    transition: var(--default-transition);
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }

  &__answer-wrap {
    height: 0;
    transition: var(--default-transition);
    overflow: hidden;

  }

  &__item {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    border-bottom: var(--grey-border);


    &._opened {
      margin-bottom: 0;
      border-bottom: 1px solid transparent;

      .faq-page {
        &__item-tab-icon {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__answer {
    background: var(--secondery-light-border);
    border-radius: 0 0 5px 5px;
    font-family: var(--h-font);
  }

  &__name {
    font-family: var(--font);
  }
}

@include respond-up(L) {
  .faq-page {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 18px;
    padding-bottom: 40px;

    &__h1, &__h2 {
      margin-bottom: 15px;
    }

    &__subtitle {
      margin-bottom: 30px;
    }

    &__answer {
      padding: 20px;
    }

    &__more-articles {
      margin-bottom: 20px;
    }
  }
}

@include respond-down(M) {
  .faq-page {
    padding: 20px 0;
    &__subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 30px;
    }

    &__item {
      font-size: 18px;
      line-height: 27px;
    }

    &__answer {
      padding: 10px;
    }

    &__h1, &__h2 {
      margin-bottom: 15px;
    }

    &__item-tab-icon {
      width: 15px;
      height: 15px;
      svg {
        width: 15px;
        height: 13px;
      }
    }

    &__right-side {
      display: none;
    }
  }
}