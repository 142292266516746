.partner-page {
  &__calc {
    display: flex;
    align-items: center;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 42px 1fr 42px;
  }

  &__value {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    padding: 12px 33px;
  }

  &__touch {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--calc-dark-grey);
  }

  &__value {
    background-color: var(--secondery-light-border);
  }

  &__card {
    border: var(--grey-border);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  &__rating {
    left: 0;
    right: unset;
    border-radius: 0 0 5px 0;
  }

  &__stat-value {
    font-weight: 700;
    color: var(--orange-color);

    &_green {
      color: var(--button-color);
    }
  }

  &__stat-container {
    display: flex;
    border-right: var(--grey-border);

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  &__button {
    cursor: pointer;
  }
}

@include respond-up(L) {
  .partner-page {
    &__h1 {
      margin-bottom: 30px;
    }

    &__partner-name {
      display: none;
    }

    &__stat-label {
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
    }

    &__wrap {
      margin-right: 20px;
    }

    &__button {
      pointer-events: auto !important;
      margin-left: 10px;
    }

    &__logo {
      object-fit: cover;
      width: 150px;
      height: 50px;
      margin: 65px 0 25px;
    }

    &__calc-container {
      margin-bottom: 25px;
    }

    &__card {
      padding: 0 30px 25px;
      margin-bottom: 30px;
    }

    &__stat-value {
      font-size: 30px;
      line-height: 35px;
      white-space: nowrap;
    }

    &__stat-container {
      padding-right: 30px;
      margin-right: 30px;
      flex-direction: column;

      &:last-child {
        border-right: none;
        margin-right: 0;
      }

      &:nth-child(4) {
        border-right: 0;
      }
    }

    &__stat-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 18px;
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 370px;
      grid-column-gap: 25px;
    }

    &__main-content {
      max-width: 750px;
    }

    &__quote {
      margin-bottom: 30px;
    }

    &__nav-menu-wrapper {
      position: relative;
    }

    &__nav {
      background: #FFFFFF;
      border: var(--grey-border);
      border-radius: 5px;
      padding: 11px 20px;
      position: sticky;
      top: 150px;
    }

    &__nav-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      width: 100%;
      margin-bottom: 22px;
    }

    &__link-container {
      display: flex;
      flex-direction: column;

      a {
        padding-left: 15px;
        position: relative;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        transition: var(--default-transition);

        &:hover {
          color: var(--orange-color);
        }

        &::before {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: var(--orange-color);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

    }
  }
}

@include respond-down(M) {
  .partner-page {
    &__logo {
      width: 100px;
      height: 33px;
    }
    &__stat-container {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      border-right: none;
    }

    &__stat-wrapper {
      padding: 0 20px 20px;
    }

    &__calc {
      flex-direction: column;
      padding: 0 20px 20px;
    }

    &__wrap {
      margin-bottom: 20px;
      width: 100%;
    }

    &__value {
      text-align: center;
    }

    &__button {
      width: 100%;
      text-align: center;
    }

    &__logo-wrapper {
      order: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 20px 20px;
    }

    &__partner-name {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
    }

    &__calc-container {
      order: 3;
    }

    &__stat-wrapper {
      order: 4;
    }

    &__h1 {
      padding-bottom: 20px;
    }

    &__nav-menu-wrapper {
      display: none;
    }

    &__stat-label {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    &__stat-value {
      font-size: 18px;
      line-height: 22px;
    }

    &__quote {
      margin-bottom: 30px;
    }
  }
}