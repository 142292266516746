.blog {
  &__list {
    display: grid;
  }
  &__h1 {
    margin-bottom: 15px;
  }
}

@include respond-up(L) {
  .blog {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }
}

@include respond(M) {
  .blog {
    &__h1 {
      margin-top: 20px;
    }
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }
}

@include respond-down(S) {
  .blog {
    padding-top: 15px;
    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }
  }
}