body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--body-bg-color);
}

input, button, form {
  font-family: var(--font);
}

.display-none {
  display: none !important;
}

.link{
  display: inline-block;
  color: var(--primary-color);
  transition: all var(--default-transition);

  &:hover {
    cursor: pointer;
  }
}

._blur {
  &_large {
    filter: blur(16px);
  }

  &_small {
    filter: blur(4px);
  }

  &_x-small {
    padding: 5px;
    filter: blur(3px);
  }

  filter: blur(8px);
}

#DEBUG {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 40px;
  z-index: 99999;

  &:after {
    content: 'XL';
    @include respond-down(L) {
      content: 'L';
    }
    @include respond-down(M) {
      content: 'M';
    }
    @include respond-down(S) {
      content: 'S';
    }
    @include respond-down(XS) {
      content: 'XS';
    }
  }

}
html {
  scroll-padding-top: calc(122px + 40px);
}

@include respond-up(L) {

}

@include respond-down(M) {

}

@keyframes load {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.test {
  ul {
    margin: -5px;

    li:not(.sizer) {
      width: 25%;

      @include respond-down(M) {
        width: 33.3333%;
      }

      @include respond-down(S) {
        width: 100%;
      }

      min-height: 30px;

      div {
        background-color: var(--search-bg);
        font-size: 26px;
        font-weight: 500;
        margin: 5px;
        display: flex;
      }

      img {
        width: 100%;
      }
    }

    .sizer {
      width: 25%;

      @include respond-down(M) {
        width: 33.3333%;
      }

      @include respond-down(S) {
        width: 100%;
      }
    }
  }
}