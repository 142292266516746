.one-article {
  overflow: hidden;
  border: 1px solid var(--secondery-light-border);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  &__full-text {
    display: none;
  }

  &__bottom-container {
    font-family: var(--h-font);
    background-color: var(--secondery-light-border);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  &__author {
    strong {
      color: var(--header-tabs);
    }
  }

  &__name {
    font-family: var(--font);
    font-weight: 700;
  }

  &__description {
    font-weight: 400;
    margin-bottom: 20px;
    font-family: var(--h-font);
  }

  &__bottom-container {
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__time {
    display: flex;
    align-items: center;
    svg {
      margin-right: 3px;
    }
  }

  &__main-image {
    object-fit: cover;
    object-position: center;

  }
}

@include respond-up(L) {
  .one-article {
    min-height: 384px;
    height: 100%;
    &._main-page {
      height: unset;
    }

    &__name {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 15px;
    }

    &__main-image {
      width: 100%;
      height: 162px;
    }

    &__description {
      font-size: 15px;
      line-height: 22px;
    }

    &__text-wrapper {
      padding: 22px 20px;
    }
  }
}

@include respond(M) {
  .one-article {
    height: 100%;
    &__name {
      margin-bottom: 10px;
      font-size: 23px;
      line-height: 27px;
    }

    &__description {
      font-size: 18px;
      line-height: 22px;
    }

    &__text-wrapper {
      padding: 22px 15px;
    }

    &__main-image {
      width: 100%;
      height: 162px;
    }
  }
}

@include respond-down(S) {
  .one-article {
    &__name {
      margin-bottom: 10px;
      font-size: 23px;
      line-height: 27px;
    }

    &__description {
      font-size: 18px;
      line-height: 22px;
    }

    &__text-wrapper {
      padding: 22px 15px;
    }
  }
}