.main-cards {
  padding-top: 30px;

  &__title {
    margin-bottom: 20px;
  }

  &__list {
    display: grid;
  }

  &__text {
    font-weight: 700;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: var(--grey-border);
  }

  &__image {
    margin-right: 15px;
  }

  &__link {
    display: flex;
    align-items: center;
  }
}

@include respond-up(L) {
  .main-cards {
    &__list {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 23px;
    }

    &__text {
      font-size: 20px;
      line-height: 23px;
    }

    &__item {
      padding: 20px;
      border-radius: 5px;
      border: var(--grey-border);
      a {
        white-space: nowrap;
        transition: var(--default-transition);
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@include respond(M) {
  .main-cards {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 23px;
    }

    &__text {
      font-size: 20px;
      line-height: 23px;
    }

    &__item {
      padding: 20px;
      border-radius: 5px;
      border: var(--grey-border);
      a {
        white-space: nowrap;
        transition: var(--default-transition);
        &:hover {
          transform: scale(1.1);
        }
      }
    }


  }
}

@include respond-down(S) {
  .main-cards {
    &__text {
      font-size: 23px;
      line-height: 27px;
    }

    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }

    &__item {
      padding: 28px 25px;
    }


  }
}