.actual {
  &__container {
    display: grid;

  }
  &__button {
    background-color: var(--button-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    border-radius: 5px;
    padding: 10px 30px;
    color: white;
  }
}

@include respond-up(L) {
  .actual {
    &__h2 {
      margin-bottom: 36px;
    }

    &__container {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 19px;
    }

    &__articles {
      margin-bottom: 40px;
      display: grid;
    }

    &__h3 {
      margin-bottom: 15px;
    }

    &__default-text {
      margin-bottom: 20px;
    }

    &__article {
      margin: 15px 0;
    }

    &__cookies {
      margin: 30px 0 0 170px;
    }

  }
}

@include respond-down(M) {
  .actual {
    &__h2 {
      margin-bottom: 19px;
    }

    &__right {
      grid-row: 1/2;
      margin-bottom: 20px;
    }

    &__cookies {
      display: none;
    }

    &__articles {
      margin-bottom: 30px;
    }

    &__h3, &__article, &__default-text {
      margin-bottom: 15px;
    }

    &__button {
      display: inline-block;
      text-align: center;
      margin-top: 10px;
      width: 100%;
    }
  }
}