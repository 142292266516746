.partners-grid {
  &__h1, &__subtitle {
    margin-bottom: 15px;
  }

  &__stats-value {
    white-space: nowrap;
    color: var(--orange-color);
  }

  &__item {
    background: #FFFFFF;
    border: var(--grey-border);
    border-radius: 5px;
    overflow: hidden;

    form {
      order: 4;
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.66);

    strong {
      margin-right: 4px;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: white;
    }
  }

  &__option {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: white;
  }

  &__stat-container {
    background-color: var(--header-tabs);
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 17px;
  }
  &__option {
    border-right: var(--grey-border);
    border-left: var(--grey-border);
  }
  &__stat-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
  }
}

@include respond-up(L) {
  .partners-grid {
    &__rating {
      padding-right: 10px;
    }
    &__option {
      padding: 0 10px;
    }
    &__stars {
      padding-left: 10px;
    }
    &__subtitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }

    &__description {
      font-family: var(--h-font);
      margin-bottom: 25px;
    }

    &__name-logo {
      white-space: nowrap;
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
    }

    &__stats-label {
      white-space: nowrap;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
    }

    &__stats-value {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
    }

    &__description {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }

    &__logo-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__logo {
      margin-bottom: 10px;
      width: 150px;
      height: 50px;
      object-fit: contain;
    }

    &__link {
      padding: 25px 49px 35px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    &__stat-container {
      border-radius: 0 5px 0 5px;
    }

    &__stats-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 45px;
      border-right: var(--grey-border);

      &:last-child {
        border-right: none;
      }
    }

    &__stats-label {
      margin-bottom: 5px;
    }

    &__stats-wrapper {
      display: flex;
      align-items: center;
    }

    &__stat-container {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
    }

    &__button {
      margin-top: 30px;
    }

  }
}

@include respond-down(M) {
  .partners-grid {
    &__description {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }

    &__subtitle {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
    }

    &__list {
      grid-row-gap: 20px;
    }

    &__description {
      margin-bottom: 15px;
    }

    &__name-logo {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
      order: 1;
    }

    &__stats-label {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    &__stats-value {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    &__button {
      order: 4;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-bottom: 15px;
    }

    &__link {
      display: flex;
      flex-direction: column;
    }

    &__stat-container {
      order: 1;
      border-radius: 5px 5px 0 0;
      padding: 5px 20px;
    }

    &__logo-wrapper {
      order: 2;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      width: 100px;
      height: 35px;
      order: 2;
    }

    &__stats-wrapper {
      order: 3;
      display: flex;
      flex-direction: column;
      padding: 0 20px 0;
    }

    &__stats-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__option {
      padding: 0 20px;
    }

    &__stats-container {
      margin-bottom: 15px;
    }
  }
}