.footer {
  background-color: var(--header-bg);
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item-link {
    line-height: 22px;
    font-family: var(--h-font);
  }
  &__copyrights {
    width: 100%;
    background-color: var(--light-grey);
    text-align: center;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

@include respond-up(M) {
  .footer {
    padding-top: 25px;
    &__column-title {
      margin-bottom: 15px;
    }
    &__copyrights {
      padding: 10px 0 5px;
      margin-top: 25px;
    }
    &__container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 23px;
    }

    &__item-link {
      font-size: 11px;
      line-height: 22px;
      font-family: var(--h-font);
    }
  }
}

@include respond-down(S) {
  .footer {
    padding: 20px 0;
    &__item-link {
      font-size: 16px;
      line-height: 27px;
      font-family: var(--h-font);
    }
    &__copyrights {
      display: none;
    }

    &__column-title {
      font-family: var(--font);
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 22px;
      line-height: 25px;
    }

    &__column {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }


  }
}