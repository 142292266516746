.main-page {
  &__h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: var(--orange-color);
    font-family: var(--font);
    margin-bottom: 15px;

  }

  &__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    font-family: var(--h-font);
  }

  &__cards {
    margin-bottom: 30px;
  }

  &__actual {
    margin-bottom: 30px;
  }

  &__bottom-description {
    font-family: var(--h-font);
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
  }
}

@include respond-up(M) {
  .main-page {
    &__h1 {
    }
  }
}

@include respond-down(S) {
  .main-page {
    padding-top: 15px;
  }
}