.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 90px 0;

  &__icon {
    margin-bottom: 60px;
  }

  &__title {
    text-align: center;
    color: var(--orange-color);
    margin-bottom: 20px;
  }

  &__text {
    text-align: center;
  }
}