/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  XS: (
    to: 374,
    spacer: 8
  ),
  S: (
    from: 375,
    to: 767,
    spacer: 16
  ),
  M: (
    from: 768,
    to: 1199,
    spacer: 20
  ),
  L: (
    from: 1200,
    to: 1920,
    spacer: 24
  ),
  XL: (
    from: 1921,
    spacer: 60
  )
);

$XS-breakpoint: 576;
$S-breakpoint: 780;
$M-breakpoint: 1024;
$L-breakpoint: 1440;


:root {

  --font: "Cuprum", sans-serif;
  --h-font: "Arial", sans-serif;

  --default-transition: .3s ease-in-out;

  --grey-hover: #797676;
  --calc-dark-grey: #E4E4E4;
  --grey-border: 1px solid #CCCCCC;
  --header-tabs: #909090;
  --header-bg: #EDEDED;
  --header-boder: #7E63A3;
  --orange-color: #FF6F09;
  --light-grey: #D9D9D9;
  --button-color: #4E8A53;
  --secondery-light-border: #F4F4F4;
}
