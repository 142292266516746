.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font);
  color: var(--h-color);
}

.h1 {
  font-weight: 700;
  line-height: 35px;
  color: var(--orange-color);
}

.h2 {
  font-weight: 700;
  line-height: 23px;
}

.h3 {
  font-weight: 700;
  line-height: 23px;
}

.default-text {
  font-family: var(--h-font);
  font-weight: 400;
  line-height: 22px;
}

.link {
  display: inline-block;
  border-bottom: 1px solid #000000;
  line-height: 1;
}

@include respond-up(L) {
  .h1 {
    font-size: 30px;
  }

  .h2 {
    font-size: 24px;
  }

  .h3 {
    font-size: 20px;
  }

  .default-text {
    font-size: 15px;
  }
}

@include respond-down(M) {
  .h1 {
    font-size: 36px;
  }

  .h2 {
    font-size: 29px;
  }

  .h3 {
    font-size: 23px;
  }

  .default-text {
    font-size: 18px;
  }
}

