.company-card {
  border-radius: 5px;
  border: var(--grey-border);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  &__head-wrapper {
    width: 100%;
    background-color: var(--header-tabs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 20px;
  }

  &__opinie {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    font-family: var(--font);
    color: white;
  }

  &__name-partner {
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    font-family: var(--font);
    margin-bottom: 40px;
  }

  &__logo {
    margin-top: 20px;
    width: 150px;
    height: 50px;
  }

}

@include respond-up(L) {
  .company-card {
    &__logo {
      margin-bottom: 10px;
    }
  }
}

@include respond-down(M) {
  .company-card {
    &__name-partner {
      font-weight: 700;
      font-size: 23px;
      line-height: 27px;
      font-family: var(--font);
    }

    &__logo {
      margin-bottom: 20px;
    }

    &__button {
      width: calc(100% - 20px);
    }
  }
}