.companies {
  &__h1-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    font-family: var(--h-font);
  }

  &__filter {
    -webkit-appearance: none;
    border: none;
    background-color: var(--secondery-light-border);
    padding: 10px 50px 10px 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    font-family: var(--h-font);
  }

  &__filter-wrapper {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }

  &__partner-grid {
    display: grid;
  }

  &__partner-item {
    position: relative;
  }

  &__button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
  }

  &__link {

  }
}

@include respond-up(L) {
  .companies {
    &__subtitle {
      margin-bottom: 15px;
    }

    &__partner-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px;
    }

    &__link {
      display: block;
      transition: all 0.3s ease;
      border-radius: 5px;
      &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@include respond-down(M) {
  .companies {
    &__subtitle {
      font-weight: 400;
      margin-bottom: 30px;
    }

    &__partner-grid {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
    }
  }
}